import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

export default function Gallery() {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      markdownRemark {
        frontmatter {
          gallery {
            id
            imgAlt
            imgSrc {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const { gallery } = data.markdownRemark.frontmatter;

  return (
    <div className=" mx-auto max-w-7xl bg-background pb-16">
      <div className="mx-auto ">
        <h2 className="sr-only">Products</h2>

        <div className="grid grid-cols-1 gap-4 px-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          {gallery.map((image) => (
            <div key={image.id} className=" w-full  overflow-hidden ">
              <GatsbyImage
                image={image.imgSrc.childImageSharp.gatsbyImageData}
                alt={image.imageAlt}
                className="h-full w-full object-cover object-center group-hover:opacity-75"
                aspectRatio={4 / 3}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
