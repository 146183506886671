import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import MenuBoxes from '../components/menuBoxes';
import HeroText from '../components/heroText';
import Contact from '../components/contact';
import Gallery from '../components/gallery';

function Index() {
  return (
    <Layout>
      <Seo title="St. Thomas Family Restaurant" />
      <MenuBoxes />
      <HeroText />
      <Gallery />
      <Contact />
    </Layout>
  );
}
export default Index;
