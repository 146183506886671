import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default function HeroText() {
  return (
    <div className="mx-auto  bg-background lg:max-w-7xl">
      <div className="mx-auto max-w-md py-16 px-2 sm:max-w-7xl sm:px-6  lg:max-w-7xl lg:px-8 lg:py-32">
        <div className="mx-auto flex flex-col items-center justify-center lg:flex-row lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          <div className="order-last px-2 pt-10 md:px-0 lg:order-first lg:w-6/12 lg:pt-0">
            <div className="overflow-hidden">
              <StaticImage
                src="../images/front-entrance.jpg"
                alt="Country Charm Country Front Entrance"
                aspectRatio={4 / 3}
              />
            </div>
          </div>
          <div className="  mx-auto mt-6 w-full px-2 sm:mt-6 md:px-0 lg:mt-0 lg:w-6/12">
            <div className="flex flex-col">
              <div className="mt-4 text-lg lg:mt-0">
                <h1 className="mx-auto  font-ibm text-3xl font-bold tracking-wide text-white sm:text-4xl lg:invisible xl:visible">
                  Country Charm
                </h1>
                <div className="mt-6  flex flex-col gap-y-4 text-lg leading-8 text-white lg:mt-2">
                  <p>
                    Country Charm is a great family restaurant, owned and
                    operated by Raymond Gocan for the last twenty five years.
                  </p>
                  <p>
                    Our goal is to make sure that each guest receives prompt,
                    professional and friendly service and have a great
                    experience while they are here.
                  </p>
                  <p>
                    The menu is ‘home’ cooked and exciting. We use not only
                    excellent fresh, local, seasonal products but also much of
                    what is “best in Elgin”.
                  </p>
                  <p>
                    We love what we do – and do it well. Why not drop by and
                    enjoy a great meal – prepared and served by our team of
                    talented and skilled people.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
